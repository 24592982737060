<template>
  <div>
    <div class="ShippingContent clearfix">
      <div class="fl">
        <p class="title">{{ $t("BussinessShippingList") }}</p>
        <div style="padding: 10px 0">
          <el-input
            size="small"
            :placeholder="$t('bussinessDialogPlaceHolder')"
            v-model="searchWord"
          >
            <el-button
              slot="append"
              @click="search"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </div>
        <div class="havaContent" v-loading="dataLoading">
          <p
            v-for="(item, index) in showData"
            :key="index"
            :title="item.name"
            @click="add(item)"
          >
            {{ item.name }}
          </p>
          <div
            v-if="!showData.length"
            style="
              text-align: center;
              line-height: 60px;
              font-size: 12px;
              color: #999;
            "
          >
            {{ LOCALE == "en" ? "No Data" : "暂无数据" }}
          </div>
        </div>

        <div class></div>
      </div>
      <div class="fr">
        <p class="title">{{ $t("selected") }}</p>
        <div class="havaContent">
          <p
            :title="item.name"
            v-for="(item, index) in checkData"
            :key="index"
            style="padding: 5px 0"
          >
            <el-tag
              size="small"
              closable
              v-if="LOCALE == 'en'"
              @close="deleteItem(index)"
              >{{
                item.name.length > 24
                  ? item.name.substr(0, 23) + "..."
                  : item.name
              }}</el-tag
            >
            <el-tag size="small" closable v-else @close="deleteItem(index)">{{
              item.name.length > 14
                ? item.name.substr(0, 14) + "..."
                : item.name
            }}</el-tag>
          </p>
        </div>
      </div>
    </div>
    <div class="clearfix" style="margin-top: 30px">
      <el-button
        plain
        size="small"
        class="fr"
        @click="clickCancel"
        style="margin-left: 11px"
        >{{ $t("cancel") }}</el-button
      >
      <el-button
        type="primary"
        size="small"
        class="fr"
        @click="save"
        :loading="loading"
        >{{ $t("confirm") }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["isSelect","compaines"],
  data() {
    return {
      searchWord: "",
      showData: [],
      checkData: [],
      loading: false,
      dataLoading: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    let data = this.compaines;
    this.checkData = this.isSelect.concat([]);
    data.forEach((element) => {
      element.name = element.code;
    });
    this.showData = data;
  },
  watch: {},
  methods: {
    add(item) {
      let ret = this.checkData.find((v) => {
        return v.id == item.id;
      });
      if (ret) {
        this.$message({
          showClose: true,
          message: this.$t("added"),
          type: "warning",
        });
        return;
      }
      if (this.checkData.length > 4) {
          this.$message({
            showClose: true,
            message: this.LOCALE == "en" ? "Select up to five" : "最多选择五个",
            type: "warning",
          });
          return;
        }
      this.checkData.push(item);
    },
    deleteItem(index) {
      this.checkData.splice(index, 1);
    },
    clickCancel() {
      this.$emit("cancelCompaines");
    },
    save() {
        if(this.checkData.length>5){
         this.$message.error(this.$t('maxNumberFive'));
        return;
      }
      this.loading = true;
      this.$emit(
        "saveCompaines",
        this.checkData.map((item) => {
          return item.id;
        })
      );
      this.$emit(
        "saveCompainesItems",
        this.checkData
      );
    },
    async search(e) {
      try {
        this.dataLoading = true;
        let params = {
          nopage: 1,
          noSave: true,
        };
        params.code = this.searchWord;
        let data = await this.$store.dispatch(
          "baseConsole/base_getCompains",
          params
        );
        if (data.success) {
          let res = data.data;
          res.forEach((element) => {
            element.name = element.code;
          });
          this.showData = res;
          this.dataLoading = false;
        }
      } catch (e) {}
    },
  },
  components: {},
};
</script>

<style scoped lang="less">
.ShippingContent {
  .fl {
    width: 250px;
    height: 500px;
    border: 1px solid #ccc;
    padding: 0 5px;

    .title {
      width: 100%;
      line-height: 35px;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid #f2f2f2;
    }
    .havaContent {
      height: 400px;
      overflow-y: scroll;
      p {
        font-size: 12px;
        line-height: 28px;
        padding: 0 10px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        &:hover {
          background: #f2f2f2;
        }
      }
    }
  }
  .fr {
    width: 250px;
    height: 500px;
    border: 1px solid #ccc;
    padding: 0 5px;
    .title {
      width: 100%;
      line-height: 35px;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid #f2f2f2;
    }
    .havaContent {
      height: 450px;
      overflow-y: scroll;
      p {
        font-size: 12px;
        line-height: 28px;
        padding: 0 10px;
        cursor: pointer;
        &:hover {
          background: #f2f2f2;
        }
      }
    }
  }
}
</style>
