<template>
  <div class=" attention baseConsoleTabPage" v-loading="loading">
    <div class="bussinessBox tableBlock">
      <div class="industry">
        <span style="display: inline-block; width: 170px; font-size: 14px">{{
          $t("companyIndustry")
        }}</span>
        <el-cascader
          v-model="value"
          :options="Industry"
          style="width: 350px"
          @change="changeIndustry"
          :placeholder="$t('pleaseSelect')"
          size="small"
          :show-all-levels="false"
          :disabled="!inspectButton('coi:coi:add')"
        ></el-cascader>
      </div>
      <!-- 服务信息 -->
      <template v-if="PJSource == 20">
        <div class="industry flexBox">
          <div style="line-height: 32px;" class="flexLe flTitile">
            {{ $t("serviceInfo") }}
          </div>
          <div class="flexCenter">
            <span
              v-for="(item, index) in data.business_classification"
              :key="index"
            >
              <el-tag
                size="small"
                v-for="(it, id) in item"
                :key="id"
                style="margin: 5px 5px 5px 0"
                @close="deleteClass(it, id, index, 0)"
                :closable="inspectButton('coi:coi:add')"
                >
                {{it.desc_en|priorFormat(it.desc_zh,LOCALE)}}
                </el-tag
              >
            </span>
          </div>
          <div class="flexRi">
            <el-button
              icon="el-icon-plus"
              type="primary"
              size="small"
              @click="addClassification('1')"
              :loading="buttonLoading"
              v-if="inspectButton('coi:coi:add')"
              :disabled="inspectHandle('coi:coi:add')"
              >{{ $t("add") }}
            </el-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="industry flexBox">
          <div style="line-height: 32px;" class="flexLe flTitile">
            {{ $t("BussinessClassification") }}
          </div>
          <div class="flexCenter">
            <span
              v-for="(item, index) in data.business_classification"
              :key="index"
            >
              <el-tag
                size="small"
                v-for="(it, id) in item"
                :key="id"
                style="margin: 5px 5px 5px 0"
                @close="deleteClass(it, id, index, 0)"
                :closable="inspectButton('coi:coi:add')"
                >
                {{it.desc_en|priorFormat(it.desc_zh,LOCALE)}}
                </el-tag
              >
            </span>
          </div>
          <div class="flexRi">
            <el-button
              icon="el-icon-plus"
              type="primary"
              size="small"
              @click="addClassification('1')"
              :loading="buttonLoading"
              v-if="inspectButton('coi:coi:add')"
              :disabled="inspectHandle('coi:coi:add')"
              >{{ $t("add") }}
            </el-button>
          </div>
        </div>
        <div class="industry flexBox">
          <div style="line-height: 32px;" class="flexLe flTitile">
            {{ $t("BussinessMajor") }}
          </div>
          <div class="flexCenter">
            <el-tag
              size="small"
              v-for="(item, index) in data.company_shipping_line"
              :key="index"
              style="margin: 5px 5px 5px 0"
              @close="deleteLine(item, index)"
              :closable="inspectButton('coi:coi:add')"
              >{{ item.name }}
            </el-tag>
          </div>
          <div class="flexRi">
            <el-button
              icon="el-icon-plus"
              type="primary"
              size="small"
              @click="addLine"
              :loading="buttonLoading"
              v-if="inspectButton('coi:coi:add')"
              :disabled="inspectHandle('coi:coi:add')"
              >{{ $t("add") }}
            </el-button>
          </div>
        </div>
        <div class="industry flexBox">
          <div style="line-height: 32px;" class="flexLe flTitile">
            {{ $t("BussinessShipping") }}
          </div>
          <div class="flexCenter">
            <el-tag
              size="small"
              v-for="(item, index) in data.company_shipping_company"
              :key="index"
              style="margin: 5px 5px 5px 0"
              @close="deleteCompaines(item, index)"
              :closable="inspectButton('coi:coi:add')"
              >{{ item.name }}
            </el-tag>
          </div>
          <div class="flexRi">
            <el-button
              icon="el-icon-plus"
              type="primary"
              size="small"
              @click="addCompaines"
              :loading="buttonLoading"
              v-if="inspectButton('coi:coi:add')"
              :disabled="inspectHandle('coi:coi:add')"
              >{{ $t("add") }}
            </el-button>
          </div>
        </div>
        <div class="industry flexBox">
          <div style="line-height: 32px;" class="flexLe flTitile">
            {{ $t("BussinessAreas") }}
          </div>
          <div class="flexCenter">
            <el-tag
              size="small"
              v-for="(item, index) in data.company_interest_area"
              :key="index"
              style="margin: 5px 5px 5px 0"
              @close="deleteArea(item, index)"
              :closable="inspectButton('coi:coi:add')"
              >{{ item.name }}
            </el-tag>
          </div>
          <div class="flexRi">
            <el-button
              icon="el-icon-plus"
              type="primary"
              size="small"
              :loading="buttonLoading"
              @click="insterstedDialog = true"
              v-if="inspectButton('coi:coi:add')"
              :disabled="inspectHandle('coi:coi:add')"
              >{{ $t("add") }}
            </el-button>
          </div>
        </div>
        <div class="industry flexBox">
          <div style="line-height: 32px;" class="flexLe flTitile">
            {{ $t("BussinessBusiness") }}
          </div>
          <div class="flexCenter">
            <span v-for="(item, index) in data.wanted_business" :key="index">
              <el-tag
                size="small"
                v-for="(it, id) in item"
                :key="id"
                style="margin: 5px 5px 5px 0"
                @close="deleteClass(it, id, index, 1)"
                :closable="inspectButton('coi:coi:add')"
                >
                {{it.desc_en|priorFormat(it.desc_zh,LOCALE)}}
                </el-tag
              >
            </span>
          </div>
          <div class="flexRi">
            <el-button
              icon="el-icon-plus"
              type="primary"
              size="small"
              :loading="buttonLoading"
              @click="addClassification('2')"
              v-if="inspectButton('coi:coi:add')"
              :disabled="inspectHandle('coi:coi:add')"
              >{{ $t("add") }}
            </el-button>
          </div>
        </div>
      </template>
    </div>
    <el-dialog
      :title="popUpTitle"
      :visible.sync="dialogVisible"
      width="900px"
      :close-on-click-modal="false"
    >
      <!--<BussinessClassification-->
      <!--    :isSelect="-->
      <!--    type == 1 ? data.business_classification : data.wanted_business-->
      <!--  "-->
      <!--    :number='5'-->
      <!--    :isCenter="true"-->
      <!--    :type="type"-->
      <!--    :classification="classification"-->
      <!--    v-if="dialogVisible"-->
      <!--    @closeClass="closeClass"-->
      <!--    @saveClass="saveClass"-->
      <!--&gt;</BussinessClassification>-->
      <component
        :is="isThatBusiness"
        :isSelect="
          type == 1 ? data.business_classification : data.wanted_business
        "
        :number="businessMaxNumber"
        :isCenter="true"
        :type="type"
        :classification="classification"
        v-if="dialogVisible"
        @closeClass="closeClass"
        @saveClass="saveClass"
      >
      </component>
    </el-dialog>
    <el-dialog
      :title="$t('BussinessMajor')"
      :visible.sync="dialogLine"
      width="900px"
      :close-on-click-modal="false"
    >
      <majorLine
        :isCenter="true"
        :number="5"
        v-if="dialogLine"
        :isSelect="data.company_shipping_line"
        @saveLine="saveLine"
        @cancelLine="cancelLine"
        :majorLine="majorLine"
      ></majorLine>
    </el-dialog>
    <el-dialog
      :title="$t('BussinessShipping')"
      :visible.sync="dialogShippingCompaines"
      width="600px"
      :close-on-click-modal="false"
    >
      <ShippingCompaines
        v-if="dialogShippingCompaines"
        :compaines="compaines"
        @cancelCompaines="cancelCompaines"
        @saveCompaines="saveCompaines"
        :isSelect="data.company_shipping_company"
      ></ShippingCompaines>
    </el-dialog>
    <el-dialog
      :title="$t('BussinessAreas')"
      :visible.sync="insterstedDialog"
      width="600px"
      :close-on-click-modal="false"
    >
      <InterestedArea
        :maxNum="5"
        @cancelArea="cancelArea"
        :isSelect="data.company_interest_area"
        @saveArea="saveArea"
        v-if="insterstedDialog"
      ></InterestedArea>
    </el-dialog>
  </div>
</template>

<script>
import BussinessClassification from "~bac/components/company/business/BussinessClassification";
import axpCompanyBusiness from "~bac/components/business/companyBusiness";
import gbkCompanyBusiness from "~bac/components/business/gbkCompanyBusiness";
import majorLine from "~bac/components/company/business/majorLine";
import ShippingCompaines from "~bac/components/company/business/ShippingCompaines";
import InterestedArea from "~bac/components/company/business/myInterestedArea";
import { Industry } from "~/baseUtils/enumValue";
import { shippingLine } from "~bac/utils/enumValue";
import { getBusinessObject } from "~bac/plugins/filters";

export default {
  layout: "memberCenter",
  name: "",
  props: {},
  data() {
    return {
      value: [],
      dialogVisible: false,
      dialogLine: false,
      dialogShippingCompaines: false,
      showAll: false,
      showAllMajor: false,
      showAllShipping: false,
      showAllAreas: false,
      showAllInterested: false,
      insterstedDialog: false,
      options: [],
      classification: [],
      majorLine: [],
      loading: false,
      Industry: [],
      type: "",
      buttonLoading: false,
      data: {},
      compaines: [],
    };
  },
  computed: {
    isThatBusiness() {
      if(this.PJSource == 21){
        return "axpCompanyBusiness"
      }else if(this.PJSource == 20){
        return "gbkCompanyBusiness"
      } else{
        return "BussinessClassification"
      }
    },
    popUpTitle() {
      if(this.type == 1 ){
        if(this.PJSource == 20){
          return this.$t("serviceInfo")
        }else{
          return this.$t('BussinessClassification') 
        }
      }else{
        return this.$t('BussinessBusiness')
      }
    },
    businessMaxNumber() {
      if(this.PJSource == 21 || this.PJSource == 20){
        return 10
      }else{
        return 5
      }
    },
  },
  created() {},
  mounted() {
    this.getData();
    if (this.LOCALE == "en") {
      Industry.forEach((element) => {
        element.label = element.label_en;
        element.children.forEach((ele) => {
          ele.label = ele.label_en;
        });
      });
    } else {
      Industry.forEach((element) => {
        element.label = element.label_zh;
        element.value = element.label_zh;
        element.children.forEach((ele) => {
          ele.label = ele.label_zh;
        });
      });
    }
    this.Industry = Industry;
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        let data = await this.$store.dispatch(
          "baseConsole/company_getBussinessInfo",
          {
            company_id: this.USER_INFO.company_id,
          }
        );
        if (data.success) {
          let res = data.data;
          if (res.company_shipping_line.length) {
            res.company_shipping_line.forEach((element) => {
              if (this.LOCALE == "en") {
                element.name = element.name_en;
              } else {
                element.name = element.name_cn;
              }
            });
          }
          if (res.company_shipping_company.length) {
            res.company_shipping_company.forEach((element) => {
              element.name = element.code;
            });
          }
          if (res.company_interest_area.length) {
            res.company_interest_area.forEach((element) => {
              if (this.LOCALE == "en") {
                element.name = element.name_en;
              } else {
                element.name = element.name_zh;
              }
            });
          }
          if (res.company_industry) {
            this.Industry.forEach((element, index) => {
              element.children.forEach((ele) => {
                if (ele.value == res.company_industry) {
                  this.value = [this.Industry[index].label, ele.value];
                }
              });
            });
          }
          this.data = res;
          this.loading = false;
        }
      } catch (e) {}
    },
    async changeIndustry(e) {
      try {
        let data = await this.$store.dispatch(
          "baseConsole/company_savaBussiness",
          {
            company_id: this.USER_INFO.company_id,
            industry: e[1],
          }
        );
        if (data.success) {
        }
      } catch (e) {}
    },
    showMore() {
      this.showAll = !this.showAll;
    },
    showMoreMajor() {
      this.showAllMajor = !this.showAllMajor;
    },
    showMoreShipping() {
      this.showAllShipping = !this.showAllShipping;
    },
    showMoreAreas() {
      this.showAllAreas = !this.showAllAreas;
    },
    showMoreInterested() {
      this.showAllInterested = !this.showAllInterested;
    },
    async addClassification(type) {
      this.classification = getBusinessObject([], true);
      this.dialogVisible = true;
      this.type = type;
    },
    async addLine() {
      this.majorLine = shippingLine;
      this.dialogLine = true;
    },
    async addCompaines() {
      if (this.compaines.length) {
        this.dialogShippingCompaines = true;
      } else {
        this.buttonLoading = true;
        try {
          let data = await this.$store.dispatch(
            "baseConsole/base_getCompains",
            {
              nopage: 1,
            }
          );
          this.buttonLoading = false;
          if (data.success) {
            this.compaines = data.data;
            this.dialogShippingCompaines = true;
          }
        } catch (e) {}
      }
    },
    cancelLine() {
      this.dialogLine = false;
    },
    closeClass() {
      this.dialogVisible = false;
    },
    cancelCompaines() {
      this.dialogShippingCompaines = false;
    },
    cancelArea() {
      this.insterstedDialog = false;
    },
    async saveClass(e, tag) {
      console.log(tag)
      let classification = e.join();
      let parmas = {
        company_id: this.USER_INFO.company_id,
      };
      if (this.type == 1) {
        parmas.business_classification = classification;
        if (tag && tag.length) {
          parmas.user_tags = JSON.stringify(tag);
        }
      } else {
        parmas.wanted_business = classification;
        if (tag && tag.length) {
          parmas.wanted_user_tags = JSON.stringify(tag);
        }
      }
      try {
        let data = await this.$store.dispatch(
          "baseConsole/company_savaBussiness",
          parmas
        );
        if (data.success) {
          this.getData();
          this.dialogVisible = false;
        }
      } catch (e) {}
    },
    async saveLine(e) {
      let line = e.join();
      try {
        let data = await this.$store.dispatch(
          "baseConsole/company_savaBussiness",
          {
            company_id: this.USER_INFO.company_id,
            company_shipping_line: line,
          }
        );
        if (data.success) {
          this.getData();
          this.dialogLine = false;
        }
      } catch (e) {}
    },
    async saveCompaines(e) {
      try {
        let data = await this.$store.dispatch(
          "baseConsole/company_savaBussiness",
          {
            company_id: this.USER_INFO.company_id,
            company_shipping_company: e.join(),
          }
        );
        if (data.success) {
          this.getData();
          this.dialogShippingCompaines = false;
        }
      } catch (e) {}
    },
    async saveArea(e) {
      let area = [];
      e.forEach((ee) => {
        area.push({
          country_id: ee.country_id,
          city_id: ee.id,
        });
      });

      try {
        let data = await this.$store.dispatch(
          "baseConsole/company_savaBussiness",
          {
            company_id: this.USER_INFO.company_id,
            wanted_area: JSON.stringify(area),
          }
        );
        if (data.success) {
          this.getData();
          this.insterstedDialog = false;
        }
      } catch (e) {}
    },
    async deleteClass(tag, index, key, type) {
      //
      console.log(tag);
      let params={}
      params.company_id=this.USER_INFO.company_id
      params.business_type=type
      if(tag.data_id){
        params.data_id=tag.data_id
      }else{
        params.enum_id=tag.id
      }
      try {
        let data = await this.$store.dispatch(
          "baseConsole/company_deleteBussiness",params);
        if (data.success) {
          if (type == 0) {
            this.data.business_classification[key].splice(index, 1);
          } else {
            this.data.wanted_business[key].splice(index, 1);
          }
        }
      } catch (e) {}
    },
    async deleteLine(tag, index) {
      try {
        let data = await this.$store.dispatch(
          "baseConsole/company_deleteBussiness",
          {
            company_id: this.USER_INFO.company_id,
            shipping_line_id: tag.id,
          }
        );
        if (data.success) {
          this.data.company_shipping_line.splice(index, 1);
        }
      } catch (e) {}
    },
    async deleteCompaines(tag, index) {
      try {
        let data = await this.$store.dispatch(
          "baseConsole/company_deleteBussiness",
          {
            company_id: this.USER_INFO.company_id,
            shipping_company_id: tag.id,
          }
        );
        if (data.success) {
          this.data.company_shipping_company.splice(index, 1);
        }
      } catch (e) {}
    },
    async deleteArea(tag, index) {
      try {
        let data = await this.$store.dispatch(
          "baseConsole/company_deleteBussiness",
          {
            company_id: this.USER_INFO.company_id,
            country_id: tag.country_id,
            city_id: tag.id,
          }
        );
        if (data.success) {
          this.data.company_interest_area.splice(index, 1);
        }
      } catch (e) {}
    },
  },
  components: {
    BussinessClassification,
    majorLine,
    ShippingCompaines,
    InterestedArea,
    axpCompanyBusiness,
    gbkCompanyBusiness
  },
};
</script>

<style scoped lang="less">
.bussinessBox {
  .title {
    font-size: 16px;
    font-weight: 900;
    padding-bottom: 15px;
  }

  .industry {
    padding: 15px 0;
    border-bottom: 1px solid #fde2e2;

    .bussinessFl {
      .flTitile {
        font-size: 14px;
      }
    }

    .showBut {
      line-height: 32px;
      font-weight: 500;
      margin-left: 15px;
      cursor: pointer;
    }
  }

  .flexBox {
    display: flex;

    .flexLe {
      width: 170px;
    }

    .flexCenter {
      flex: 1;
    }

    .flexRi {
      width: 80px;
      margin-left: 20px;
    }
  }
}
</style>
