<template>
  <div>
    <div class="ShippingContent clearfix">
      <div class="fl">
        <p class="title">{{$t('bussinessCountryCity')}}</p>
        <div style="padding:10px 0 ;">
          <CountrySelect v-model="searchWord" :locale="LOCALE" :placeTitle="$t('country')"></CountrySelect>
        </div>
        <div class="havaContent" v-loading="dataLoading">
          <p
            v-for="(item,index) in showData"
            :key="index"
            :title="LOCALE == 'en'? item.name_en:item.name_zh"
            @click="add(item)"
          >{{LOCALE == 'en'? item.name_en:item.name_zh}}</p>
          <div
            v-if="!showData.length"
            style="text-align:center;line-height:60px;font-size:12px;color:#999"
          >{{LOCALE == 'en' ? 'No Data':'暂无数据'}}</div>
        </div>

        <div class></div>
      </div>
      <div class="fr">
        <p class="title">{{$t('selected')}}</p>
        <div class="havaContent">
          <p
            :title="LOCALE == 'en'? item.name_en:item.name_zh"
            v-for="(item,index) in checkData"
            :key="index"
            style="padding:5px 0;"
          >
            <el-tag
              size="small"
              closable
              v-if="LOCALE == 'en'"
              @close="deleteItem(index)"
            >{{item.name_en.length>24? item.name_en.substr(0,24)+'...' :item.name_en}}</el-tag>
            <el-tag
              size="small"
              closable
              v-else
              @close="deleteItem(index)"
            >{{item.name_zh.length>14?item.name_zh.substr(0,14)+'...':item.name_zh}}</el-tag>
          </p>
        </div>
      </div>
    </div>

    <div class="clearfix tr" style="margin-top:30px">
      <el-button
        type="primary"
        size="small"
        @click="save"
        :loading="loading"
        style="margin-left:11px"
      >{{$t('confirm')}}</el-button>
      <el-button size="small" @click="clickCancel">{{$t('cancel')}}</el-button>
    </div>
  </div>
</template>

<script>
import CountrySelect from "~/baseComponents/CountrySelect";
import { getCityListByCountryId } from "~/baseUtils/AddressConfig";
export default {
  props: ["isSelect", "maxNum"],
  data() {
    return {
      searchWord: null,
      showData: [],
      checkData: [],
      loading: false,
      dataLoading: false
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.checkData = this.isSelect.concat([]);
  },
  watch: {
    isSelect(e) {
      this.checkData = this.isSelect.concat([]);
    },
    async searchWord(e) {
      if (!e) {
        this.showData = [];
        return;
      }
      this.showData = getCityListByCountryId(e, this.LOCALE);
    }
  },
  methods: {
    add(item) {
      let ret = this.checkData.find(v => {
        return v.id == item.id;
      });
      if (ret) {
        this.$message({
          showClose: true,
          message: this.LOCALE == "en" ? "Added" : "已添加",
          type: "warning"
        });
        return;
      }
      if (this.maxNum) {
        if (this.checkData.length > 4) {
          this.$message({
            showClose: true,
            message: this.LOCALE == "en" ? "Select up to five" : "最多选择五个",
            type: "warning"
          });
          return;
        }
      }

      this.checkData.push(item);
    },
    deleteItem(index) {
      this.checkData.splice(index, 1);
    },
    clickCancel() {
      this.$emit("cancelArea");
    },
    save() {
       if(this.checkData.length>5){
          this.$message({
            showClose: true,
            message: this.LOCALE == "en" ? "Select up to five" : "最多选择五个",
            type: "warning",
          });
        return;
      }
      this.loading = true;

      this.$emit("saveArea", this.checkData);
    }
  },
  components: { CountrySelect }
};
</script>

<style scoped lang="less">
.ShippingContent {
  .fl {
    width: 250px;
    height: 500px;
    border: 1px solid #ccc;
    padding: 0 5px;

    .title {
      width: 100%;
      line-height: 35px;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid #f2f2f2;
    }
    .havaContent {
      height: 400px;
      overflow-y: scroll;
      p {
        font-size: 12px;
        line-height: 28px;
        padding: 0 10px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        &:hover {
          background: #f2f2f2;
        }
      }
    }
  }
  .fr {
    width: 250px;
    height: 500px;
    border: 1px solid #ccc;
    padding: 0 5px;
    .title {
      width: 100%;
      line-height: 35px;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid #f2f2f2;
    }
    .havaContent {
      height: 450px;
      overflow-y: scroll;
      p {
        font-size: 12px;
        line-height: 28px;
        padding: 0 10px;
        cursor: pointer;
        &:hover {
          background: #fff;
        }
      }
    }
  }
}
</style>
