<template>
  <div class="wrap">
    <div class="serviceInfo">
      <!-- <div class="title">
        {{ $t("serviceInfo") }}
      </div> -->
      <div class="content">
        <el-checkbox-group
          v-model="ServicesInfo"
          size="mini"
          @change="maxServicesInfochange"
          :max="maxServiceInfoLength"
        >
          <el-checkbox-button
            v-for="city in classification['Services Info']"
            :label="city.id"
            :key="city.id"
            size="small"
          >
            {{ city.desc_en | priorFormat(city.desc_zh, LOCALE) }}
          </el-checkbox-button>
          <el-tag
            class="itemTag"
            size="medium"
            closable
            v-for="(item, index) in userTag"
            :key="index"
            @close="clearTag(index)"
          >
            {{ item.desc_en | priorFormat(item.desc_zh, LOCALE) }}
          </el-tag>
        </el-checkbox-group>
      </div>
    </div>
    <div class="addBtn">
      <el-button size="small" type="primary" @click="openAddBusinessDialog">
        {{ $t("addusertagBusiness") }}
      </el-button>
    </div>
    <div class="six">
      <div v-if="number && !isCenter" class="btnWrap">
        <el-button
          type="primary"
          size="small"
          @click="saveClass"
          style="margin-left: 11px"
          :loading="loading"
          >{{ $t("confirm") }}
        </el-button>
        <el-button size="small" type="text" @click="clickCancel"
          >{{ $t("cancel") }}
        </el-button>
      </div>
      <div v-else class="btnWrap">
        <el-button
          plain
          size="small"
          style="margin-left: 11px"
          @click="clickCancel"
          >{{ $t("cancel") }}
        </el-button>
        <el-button plain size="small" @click="clearCheck"
          >{{ $t("clearup") }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="saveClass"
          :loading="loading"
          >{{ $t("confirm") }}
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="$t('addbusinessTgas')"
      :visible.sync="addBusinessDialog"
      width="400px"
      @close="clearInputData"
      append-to-body
    >
      <div class="inputWrap">
        <el-input
          size="small"
          type="text"
          :placeholder="$t('pleaseselectbusinesscontent')"
          v-model="addInputModel"
          :maxlength="inputMax"
          show-word-limit
        >
        </el-input>
      </div>
      <div class="dialogBtnWrap tr" style="margin-top:20px;">
        <el-button size="small" @click="clearInputData">
          {{ $t("cancel") }}
        </el-button>
        <el-button size="small" type="primary" @click="inputSave">
          {{ $t("save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      //最大选择数
      type: Number,
      default: 10,
    },
    classification: {
      //所有固定的数据
      type: Object,
      default: function() {
        return {};
      },
    },
    isSelect: {
      //已选择的数据
      type: Object,
      default: function() {
        return {};
      },
    },
    isCenter: {
      //是否显示清空选项
      type: Boolean,
      default: true,
    },
    type: {
      //1是优势业务 2是目标业务
      type: String,
      default: "",
    },
  },
  data() {
    return {
      addBusinessDialog: false,
      addInputModel: "",
      maxNumber: 0,
      Conventional: [],
      Dangerous: [],
      Particular: [],
      Services: [],
      Other: [],
      ServicesInfo:[],
      userTag: [],
      loading: false,
      disabledBtn: false,
      max1: 0,
      max2: 0,
      max3: 0,
      max4: 0,
      max5: 0,
      max6: 0,
      max7: 0,
      maxNumber: 0,
    };
  },
  watch: {
    isSelect(e) {
      let classification = this.isSelect;
      if (classification["Convertional"]) {
        this.Conventional = classification["Convertional"].map((item) => {
          return item.id;
        });
        this.max1 = this.Conventional.length;
      }
      if (classification["Dangerous Cargo"]) {
        this.Dangerous = classification["Dangerous Cargo"].map((item) => {
          return item.id;
        });
        this.max2 = this.Dangerous.length;
      }
      if (classification["Particular Cargo"]) {
        this.Particular = classification["Particular Cargo"].map((item) => {
          return item.id;
        });
        this.max3 = this.Particular.length;
      }
      if (classification["Services"]) {
        this.Services = classification["Services"].map((item) => {
          return item.id;
        });
        this.max4 = this.Services.length;
      }
      if (classification["Other convertional"]) {
        this.Other = classification["Other convertional"].map((item) => {
          return item.id;
        });
        this.max5 = this.Other.length;
      }
      if (classification["Services Info"]) {
        this.ServicesInfo = classification["Services Info"].map((item) => {
          return item.id + "";
        });
        this.max7 = this.ServicesInfo.length;
      }
      if (classification["user_tags"]) {
        this.userTag = classification["user_tags"];
        this.max6 = this.userTag.length;
      }
    },
  },
  computed: {
    inputMax() {
      return this.LOCALE === "en" ? 50 : 10;
    },
    maxOne() {
      return (
        this.maxNumber -
        this.max2 -
        this.max3 -
        this.max4 -
        this.max5 -
        this.max6
      );
    },
    maxTwo() {
      return (
        this.maxNumber -
        this.max1 -
        this.max3 -
        this.max4 -
        this.max5 -
        this.max6
      );
    },
    maxThree() {
      return (
        this.maxNumber -
        this.max1 -
        this.max2 -
        this.max4 -
        this.max5 -
        this.max6
      );
    },
    maxFoure() {
      return (
        this.maxNumber -
        this.max1 -
        this.max2 -
        this.max3 -
        this.max5 -
        this.max6
      );
    },
    maxFive() {
      return (
        this.maxNumber -
        this.max1 -
        this.max2 -
        this.max3 -
        this.max4 -
        this.max6
      );
    },
    maxServiceInfoLength() {
      return (
        this.maxNumber -
        this.max6
      );
    },
    maxSix() {
      return (
        this.maxNumber -
        this.max7
      );
    },
  },
  created() {
  },
  mounted() {
    this.maxNumber = this.number;
    let length =
      (this.isSelect["Convertional"]
        ? this.isSelect["Convertional"].length
        : 0) +
      (this.isSelect["Dangerous Cargo"]
        ? this.isSelect["Dangerous Cargo"].length
        : 0) +
      (this.isSelect["Particular Cargo"]
        ? this.isSelect["Particular Cargo"].length
        : 0) +
      (this.isSelect["Services Info"]
        ? this.isSelect["Services Info"].length
        : 0) +
      (this.isSelect["Services"] ? this.isSelect["Services"].length : 0) +
      (this.isSelect["Other convertional"]
        ? this.isSelect["Other convertional"].length
        : 0);
    if (length > this.number) {
      this.maxNumber = length;
    }
    this.init();
  },
  methods: {
    openAddBusinessDialog() {
      if (this.userTag.length >= this.maxSix) {
        this.$message({
          showClose: true,
          message: this.LOCALE == "en" ? "Select up to Ten" : "最多选择十个",
          type: "warning",
        });
        return;
      }
      this.addBusinessDialog = true;
    },
    clearInputData() {
      this.addInputModel = "";
      this.addBusinessDialog = false;
    },
    inputSave() {
      if (!this.addInputModel) {
        this.$message({
          message: this.$t("contentnotempty"),
          type: "warning",
        });
        return;
      }
      let obj = {
        desc_zh: this.LOCALE === "zh" ? this.addInputModel : "",
        desc_en: this.LOCALE === "en" ? this.addInputModel : "",
      };
      this.userTag.push(obj);
      this.clearInputData();
      this.userTagLengthChange();
    },
    userTagLengthChange() {
      this.max6 = this.userTag.length;
      this.resetMaxNumber();
    },
    init() {
      this.$nextTick(() => {
        let classification = JSON.parse(JSON.stringify(this.isSelect));
        if (classification["Convertional"]) {
          this.Conventional = classification["Convertional"].map((item) => {
            return item.id;
          });
          this.max1 = this.Conventional.length;
        }
        if (classification["Dangerous Cargo"]) {
          this.Dangerous = classification["Dangerous Cargo"].map((item) => {
            return item.id;
          });
          this.max2 = this.Dangerous.length;
        }
        if (classification["Particular Cargo"]) {
          this.Particular = classification["Particular Cargo"].map((item) => {
            return item.id;
          });
          this.max3 = this.Particular.length;
        }
        if (classification["Services"]) {
          this.Services = classification["Services"].map((item) => {
            return item.id;
          });
          this.max4 = this.Services.length;
        }
        if (classification["Other convertional"]) {
          this.Other = classification["Other convertional"].map((item) => {
            return item.id;
          });
          this.max5 = this.Other.length;
        }
        if (classification["Services Info"]) {
          this.ServicesInfo = classification["Services Info"].map((item) => {
            return item.id + "";
          });
          this.max7 = this.ServicesInfo.length;
        }
        if (classification["user_tags"]) {
          this.userTag = classification["user_tags"];
          this.max6 = this.userTag.length;
        }
      });
    },
    change(e) {
      this.max1 = e.length;
      this.resetMaxNumber();
    },
    Dangerouschange(e) {
      this.max2 = e.length;
      this.resetMaxNumber();
    },
    Particularchange(e) {
      this.max3 = e.length;
      this.resetMaxNumber();
    },
    Serviceschange(e) {
      this.max4 = e.length;
      this.resetMaxNumber();
    },
    Otherchange(e) {
      this.max5 = e.length;
      this.resetMaxNumber();
    },
    maxServicesInfochange(e) {
      this.max7 = e.length;
      this.resetMaxNumber();
    },
    resetMaxNumber() {
      let allLength =
        this.max1 + this.max2 + this.max3 + this.max4 + this.max5 + this.max6 + this.max7;
      if (allLength < this.number + 1) {
        this.maxNumber = this.number;
      } else {
        this.maxNumber = allLength;
      }
    },
    checkAll() {
      let classification = this.classification;

      this.Conventional = classification["Convertional"].map((item) => {
        return item.id;
      });
      this.Dangerous = classification["Dangerous Cargo"].map((item) => {
        return item.id;
      });
      this.Particular = classification["Particular Cargo"].map((item) => {
        return item.id;
      });
      this.Services = classification["Services"].map((item) => {
        return item.id;
      });
      this.Other = classification["Other convertional"].map((item) => {
        return item.id;
      });
      this.ServicesInfo = classification["Services Info"].map((item) => {
        return item.id + "";
      });
    },
    clearCheck() {
      this.Conventional = [];
      this.Dangerous = [];
      this.Particular = [];
      this.Services = [];
      this.Other = [];
      this.ServicesInfo = [];
      this.userTag = [];
      this.max1 = 0;
      this.max2 = 0;
      this.max3 = 0;
      this.max4 = 0;
      this.max5 = 0;
      this.max6 = 0;
    },
    clickCancel() {
      this.$emit("closeClass");
    },
    saveClass() {
      let data = this.Conventional.concat(this.Dangerous)
        .concat(this.Particular)
        .concat(this.Services)
        .concat(this.ServicesInfo)
        .concat(this.Other);
      if (this.number == 5) {
        if (data.length > 5) {
          this.$message({
            showClose: true,
            message: this.LOCALE == "en" ? "Select up to five" : "最多选择五个",
            type: "warning",
          });
          return;
        }
      } else if (this.number === 10) {
        if (data.length + this.userTag.length > 10) {
          this.$message({
            showClose: true,
            message: this.LOCALE == "en" ? "Select up to Ten" : "最多选择十个",
            type: "warning",
          });
          return;
        }
      }
      this.loading = true;
      this.$emit("saveClass", data, this.userTag);
    },
    clearTag(index) {
      this.userTag.splice(index, 1);
      this.userTagLengthChange();
    },
  },
};
</script>

<style scoped lang="less">
.wrap {
  .title {
    line-height: 26px;
    border-bottom: 1px solid #f2f2f2;
  }

  .content {
    padding: 15px 0 5px;
  }
  .addBtn {
    margin-top: 10px;
  }
  .six {
    margin-top: 10px;
    text-align: right;
  }
  .itemTag {
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    top: -2px;
  }
}
</style>
